import ImageBlock from "./imageBlock";
import ImageButton from "./imageButton";
import ListBlock from "./listBlock";
import LogoBlock from "./logoBlock";
import SocialBlock from "./socialBlock";
import TextBlock from "./textBlock";
import TextLinkBlock from "./textLinkBlock";
import ThreeIRow from './three_i_row';
import TextTwoBlock from './text_two_block';
import TextThreeBlock from './text_three_block';
import AIHeader from './aiheader.js';
import TextImage from "./textImage";
import TwoTextImage from "./two_textImage";
import ThreeTextImage from "./three_textImage";
import LogoAIBlock from "./logoAIBlock";
import Ailist from "./ailist.js";
import Aifooter from "./aifooter.js";
import Aioffers from "./aioffers.js";

export default function generateBlock(obj) {
    switch (obj.type) {
        case 'text':
            return <TextBlock type={obj.type} id={obj.id} />;
        case 'list-block':
            return <ListBlock id={obj.id} obj={obj.type} type={obj.type} />;

        case 'two-text-image':
            return <TwoTextImage type={obj.type} id={obj.id} block1={obj.childrens[0]} block2={obj.childrens[1]} />;
        case 'three-text-image':
            return <ThreeTextImage type={obj.type} id={obj.id} block1={obj.childrens[0]} block2={obj.childrens[1]} block3={obj.childrens[2]} />;
        case 'text-image':
            return <TextImage id={obj.id} obj={obj.type} type={obj.type} />;
        case 'social-block':
            return <SocialBlock id={obj.id} block1={obj.childrens[0]} block2={obj.childrens[1]}
                block3={obj.childrens[2]} block4={obj.childrens[3]} type={obj.type} />;
        case 'logo-block':
            return <LogoBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} />;
        case 'image':
            return <ImageBlock type={obj.type} id={obj.id} />;
        case 'ai-logo-1':
            return <LogoAIBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'ai-logo-2':
            return <LogoAIBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'ai-logo-3':
            return <LogoAIBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'ai-logo-4':
            return <LogoAIBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'ai-logo-5':
            return <LogoAIBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'ai-logo-6':
            return <LogoAIBlock type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'ai-header-1':
            return <AIHeader id={obj.id} obj={obj.type} type={obj.type} />;
        case 'ai-header-2':
            return <AIHeader id={obj.id} obj={obj.type} type={obj.type} />;
        case 'ai-header-3':
            return <AIHeader id={obj.id} obj={obj.type} type={obj.type} />;
        case 'ai-header-4':
            return <AIHeader id={obj.id} obj={obj.type} type={obj.type} />;
        case 'ai-header-5':
            return <AIHeader id={obj.id} obj={obj.type} type={obj.type} />;
        case 'ai-header-6':
            return <AIHeader id={obj.id} obj={obj.type} type={obj.type} />;
        case 'ai-offers-1':
            return <Aioffers id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-offers-2':
            return <Aioffers id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-offers-3':
            return <Aioffers id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-offers-4':
            return <Aioffers id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-1':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-2':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-3':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-4':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-5':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-6':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-7':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'ai-list-8':
            return <Ailist id={obj.id} obj={obj.type} type={obj.type} childrens={obj.childrens} />;
        case 'three-i-row':
            return <ThreeIRow type={obj.type} id={obj.id} img1={obj.childrens[0]} img2={obj.childrens[1]} img3={obj.childrens[2]} />;
        case 'link-img':
            return <ImageButton type={obj.type} id={obj.id} />;
        case 'text-link':
            return <TextLinkBlock type={obj.type} id={obj.id} />;
        case 'text-two-block':
            return <TextTwoBlock type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-1':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-2':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-3':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-4':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-5':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-6':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-7':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-8':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'ai-footer-9':
            return <Aifooter type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} />;
        case 'text-three-block':
            return <TextThreeBlock type={obj.type} id={obj.id} text1={obj.childrens[0]} text2={obj.childrens[1]} text3={obj.childrens[2]} />;
        default:
            return <></>;
    }
}