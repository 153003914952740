import { Row } from "@react-email/row";
import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getAiFooterBlock = (txt1, txt2, params, type) => {
    var params_1 = txt1;
    var params_2 = txt2;

    var content = {};
    var badWidth = 602;

    badWidth = (badWidth - parseInt(params.paddingLeft) - parseInt(params.paddingRight)).toString() + 'px';

    if (type === 'ai-footer-1') {
        content = <tr>
            <td width={300} style={{ verticalAlign: 'top' }} >{getTextBlock(params_1)}</td >
            <td >{getTextBlock(params_2)}</td>
        </tr >;
    } else {
        content = <>
            <tr>
                <td style={{paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight}}>
                    {getTextBlock(params_1, undefined, undefined, undefined, undefined, badWidth)}
                </td>

            </tr>
            <tr>
                <td style={{paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom}}>
                    {getTextBlock(params_2, undefined, undefined, undefined, undefined, badWidth)}
                </td>
            </tr>
        </>;
    }


    return <table cellSpacing={0} cellPadding={0} width={600}  style={{
       
        
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor, maxWidth: '601px', width: '600px'
    }}>
        {content}

    </table>;
}