import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArr, setAIImgLoading, setAiImages } from '../../actions/actions';
import { bindActionCreators } from 'redux';
import { Fade } from 'react-reveal';
import { icons } from '../../assets/icons/index';
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';
import './styles.css';
import '../AIText/styles.css';
class AiImg extends Component {
    constructor(props) {
        super(props);
        this.colors = ['DarkGray', 'black', 'Coral', 'Dark Brown', 'Orange', 'Yellow', 'DarkOliveGreen', 'Green', 'Aquamarine', 'Cyan',
            'Blue', 'DarkViolet', 'Fuchsia'];
            var ai = false;
            if (props.ai !== undefined && props.ai !== null){
                ai = props.ai;
            }
        this.state = {
            prompt: '',
            openTone: false,
            openSize: false,
            color: '',
            aspectRatio: 0,
            ai: ai,
            error_params: false,
            selectedImg: false,
            selectImg: '',
            openColor: false
            /*
            'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
                'https://i0.wp.com/digital-photography-school.com/wp-content/uploads/2011/11/square-format-01.jpg?resize=600%2C600&ssl=1',
                'https://img.freepik.com/premium-vector/square-shape-printable_457177-37.jpg?w=2000',
                'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010714/18_001.jpg'
            */
        };
    }
    plusWords = (plus) => {
        var words = this.state.words;
        words += plus;
        if (words >= 20) {
            this.setState({ words: words });
        }

    }
    componentDidMount = () => {
        document.getElementById('i3').checked = true;
        this.props.setAIImgLoading(false);
        this.props.setAiImages([]);
    }


    getCheckedTone = () => {
        var selected = 'i3';
        for (var i = 1; i < 9; i++) {
            try {
                var elem = document.getElementById('i' + i);
                if (elem.checked) {
                    selected = elem.id;
                    break;
                }
            }
            catch (e) {
                continue;
            }

        }
        switch (selected) {
            case 'i1':
                return '3d';
            case 'i2':
                return 'Abstract';
            case 'i3':
                return 'Illustration';
            case 'i4':
                return 'Photorealistic';
            case 'i5':
                return 'Cinematic';
            case 'i6':
                return 'Collage art';
            case 'i7':
                return 'Professional photo';
            default:
                return "Collage art";
        }
    }
    addImage = (_for, _src) => {
        var src = 'https://cdn-icons-png.flaticon.com/128/3670/3670151.png';
        if (_src !== null && _src !== undefined) {
            src = _src;
        }
        var id = this.uuidv4();
        var _obj = {
            id: id,
            type: 'image',
            childrens: [],
            params: {
                height: 'auto',
                width: '100%',
                paddingTop: _for === undefined ? '12px' : '0px',
                align: 'center',
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingBottom: _for === undefined ? '12px' : '0px',
                backgroundColor: '#fff',
                marginTop: '0px',
                for: _for === undefined ? -99 : _for,
                marginRight: '0px',
                marginLeft: '0px',
                marginBottom: '0px',
                alt: '',
                src: src
            }
        }
        this.props.arr.push(_obj)
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
        return id;

    }
    applyImg = () => {
        var img = this.state.selectImg;
        var arr = this.props.arr;
        if (this.props.selectedElm !== -122 && this.props.selectedElm !== undefined) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === this.props.selectedElm) {
                    var item = arr[i];
                    item.params.src = img;
                    arr[i] = item;
                    break;
                }
            }
        } else {
            this.addImage(-99, img);
        }
        if (this.state.ai === false){
            this.props.setCurrentPage('constructor');
        }else{
            this.props.setCurrentPage('ai-constructor', 2);
        }
       
        //  Array.prototype.slice.call(arr).forEach((item) => { this.addText(-99, item); });

        //   console.log(arr);
    }
    generateImg = () => {
        if (this.state.prompt.length === 0 && this.state.color !== '') {
            return;
        }
        var json = {
            token: this.props.token,
            color: this.state.color,
            promnt: this.state.prompt,
            aspectRatio: this.state.aspectRatio,
            style: this.getCheckedTone()
        };
        this.props.setAIImgLoading(true);

        fetch('AIImageMid', {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => {
            this.props.setAiImages(x.imgs);
            this.props.setAIImgLoading(false);
        });
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    saveImage = (x) => {
        var json = {
            token: this.props.token,
            base64: x
        };
        fetch('SaveEditImage', {
            method: "POST",
            body: JSON.stringify(json), 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => x.json()).then(x => this.setState({ selectImg: x.path }));
    }
    render() {
        return (
            <div className="ai-text-screen">

                <div className='ai-header'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: '1', paddingLeft: '54px' }}>
                        <img src={icons.adbot_mini} />
                        <p style={{ marginLeft: '6px', fontWeight: 700, fontSize: '14px', fontFamily: 'Montserrat' }}>Adbot Image Generator</p></div>
                    <div onClick={() => {
                        if (this.state.ai === true){
                            this.props.setCurrentPage('ai_constructor', 2);
                        }else{
                            this.props.setCurrentPage('constructor');
                        }
                    }} style={{ cursor: 'pointer', fontFamily: 'Montserrat' }}>Cancel</div>
                </div>
                <div className='ai-text-content'>
                    <div className='ai-text-params'>
                        <div>
                            <p>Describe the image</p>
                            <textarea placeholder='A man paying by credit card' onInput={(e) => this.setState({ prompt: e.target.value })}>

                            </textarea>
                            <br />
                            <br />
                            <br />

                            <p onClick={() => this.setState({ openTone: !this.state.openTone })} style={{ cursor: 'pointer' }}><img style={{ filter: 'brightness(0%)', marginRight: '4px', marginLeft: '4px', marginTop: '2px', rotate: this.state.openTone ? '90deg' : '0deg', }} src={icons.next} /> Style</p>
                            <Fade top when={this.state.openTone} duration={300}>
                                <div style={{ display: this.state.openTone ? '' : 'none' }} className='words-block'>
                                    <div className='select-tone'>
                                        <div>
                                            <input id='i1' type='radio' className='tone-input' name="tone" />
                                            <label for="i1" className='tone-label'>
                                                3d
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i2' type='radio' className='tone-input' name="tone" />
                                            <label for="i2" className='tone-label'>
                                                Abstract
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i3' type='radio' className='tone-input' name="tone" />
                                            <label for="i3" className='tone-label'>
                                                Illustration
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i5' type='radio' className='tone-input' name="tone" />
                                            <label for="i5" className='tone-label'>
                                                Cinematic
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i6' type='radio' className='tone-input' name="tone" />
                                            <label for="i6" className='tone-label'>
                                                Collage art
                                            </label>
                                        </div>
                                        <div>
                                            <input id='i7' type='radio' className='tone-input' name="tone" />
                                            <label for="i7" className='tone-label'>
                                                Professional photo
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </Fade>
                            <br />
                            <br />
                            <br />
                            <p onClick={() => this.setState({ openColor: !this.state.openColor })} style={{ cursor: 'pointer' }}><img style={{ filter: 'brightness(0%)', marginRight: '4px', marginLeft: '4px', marginTop: '2px', rotate: this.state.openColor ? '90deg' : '0deg', }} src={icons.next} /> Colors</p>
                            <Fade top when={this.state.openColor} duration={300}>
                                <div style={{ display: this.state.openColor ? '' : 'none' }} className='words-block'>
                                    <div className='select-tone select-color'>
                                        <div className='color-box' onClick={() => this.setState({ color: 'neutral' })}
                                            style={{
                                                marginLeft: '8px',
                                                position: 'relative',
                                                outline: this.state.color === 'neutral' ? '4px solid #34B9A0' : '4px solid transparent', backgroundColor: '#988792', borderRadius: '4px',
                                                width: '24px', height: '24px', cursor: 'pointer'
                                            }}
                                        ><span class="color-tooltip">Neutral</span></div>
                                        {this.colors.map(x => {
                                            return <div className='color-box' onClick={() => this.setState({ color: x })} style={{
                                                marginLeft: '8px',
                                                position: 'relative',
                                                outline: this.state.color === x ? '4px solid #34B9A0' : '4px solid transparent', backgroundColor: x === "Dark Brown" ? "#654321" : x, borderRadius: '4px',
                                                width: '24px', height: '24px', cursor: 'pointer'
                                            }}><span class="color-tooltip">{x}</span></div>
                                        })}


                                    </div>
                                </div>

                            </Fade>
                            <br />
                            <br />
                            <br />
                            <p onClick={() => this.setState({ openSize: !this.state.openSize })} style={{ cursor: 'pointer' }}><img style={{ filter: 'brightness(0%)', marginRight: '4px', marginLeft: '4px', marginTop: '2px', rotate: this.state.openSize ? '90deg' : '0deg', }} src={icons.next} /> Size</p>
                            <Fade top when={this.state.openSize} duration={300}>
                                <div style={{ display: this.state.openSize ? '' : 'none' }} className='words-block'>
                                    <div className='select-tone'>
                                        <div className={`size-box ${this.state.aspectRatio === 0 ? 'size-box-selected' : ''}`} onClick={() => this.setState({ aspectRatio: 0 })}>
                                            <div className='size-box__top'>
                                                <div style={{ width: '22px', height: '22px', border: '2px solid #14201E', paddingBottom: '8px' }}></div>
                                            </div>
                                            <div className='size-box__bottom'>
                                                <p className='size-box__bottom-text'>Square</p>
                                                <p className='size-box__bottom-text'>1:1</p>
                                            </div>
                                        </div>
                                        <div className={`size-box ${this.state.aspectRatio === 3 ? 'size-box-selected' : ''}`} onClick={() => this.setState({ aspectRatio: 3 })}>
                                            <div className='size-box__top'>
                                                <div style={{ width: '30px', height: '20px', border: '2px solid #14201E', paddingBottom: '8px' }}></div>
                                            </div>
                                            <div className='size-box__bottom'>
                                                <p className='size-box__bottom-text'>Landscape</p>
                                                <p className='size-box__bottom-text'>3:2</p>
                                            </div>
                                        </div>
                                        <div className={`size-box ${this.state.aspectRatio === 2 ? 'size-box-selected' : ''}`} onClick={() => this.setState({ aspectRatio: 2 })}>
                                            <div className='size-box__top'>
                                                <div style={{ width: '20px', height: '30px', border: '2px solid #14201E', paddingBottom: '8px' }}></div>
                                            </div>
                                            <div className='size-box__bottom'>
                                                <p className='size-box__bottom-text'>Portret</p>
                                                <p className='size-box__bottom-text'>2:3</p>
                                            </div>
                                        </div>
                                        <div className={`size-box ${this.state.aspectRatio === 1 ? 'size-box-selected' : ''}`} onClick={() => this.setState({ aspectRatio: 1 })}>
                                            <div className='size-box__top'>
                                                <div style={{ width: '30px', height: '15px', border: '2px solid #14201E', paddingBottom: '8px' }}></div>
                                            </div>
                                            <div className='size-box__bottom'>
                                                <p className='size-box__bottom-text'>Banner</p>
                                                <p className='size-box__bottom-text'>2:1</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Fade>
                            <br />
                            <br />
                            <br />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <p onClick={() => this.generateImg()} className='generate-button'><img style={{ filter: 'brightness(0%)', transform: 'scale(0.8)', position: 'relative', top: '2px', left: this.props.loadingAIImages ? '35px' : '0px' }} src={this.props.loadingAIImages ? icons.m_loading : icons.sparkles} /> <span style={{ visibility: this.props.loadingAIImages ? 'hidden' : 'visible' }}>Generate</span></p>
                        </div>
                    </div>
                    <div className='ai-text-box'>
                        <div style={{ display: this.state.selectedImg ? 'none' : this.state.aspectRatio === 0 ? 'flex' : 'none', }} className='img-blocks'>
                            {this.props.generatedImages.length > 0 && !this.props.loadingAIImages && this.props.generatedImages.map(x =>
                                <img
                                    onClick={() => this.setState({ selectImg: x, selectedImg: true })}
                                    src={x}
                                    style={{ width: '15vw', height: '14vw', marginLeft: '16px' }}
                                />
                            )}

                            {(this.props.generatedImages.length === 0 || this.props.loadingAIImages) && (
                                <>
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '14vw', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '14vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '14vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '14vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                </>
                            )}
                        </div>
                        <div style={{ display: this.state.selectedImg ? 'none' : this.state.aspectRatio === 1 ? 'flex' : 'none', }} className='img-blocks'>
                            {this.props.generatedImages.length > 0 && !this.props.loadingAIImages && this.props.generatedImages.map(x =>
                                <img
                                    onClick={() => this.setState({ selectImg: x, selectedImg: true })}
                                    src={x}
                                    style={{ width: '15vw', height: '9vw', marginLeft: '16px' }}
                                />
                            )}
                            {(this.props.generatedImages.length === 0 || this.props.loadingAIImages) && (
                                <>
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '9vw', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '9vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '9vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '9vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                </>
                            )}
                        </div>
                        <div style={{ display: this.state.selectedImg ? 'none' : this.state.aspectRatio === 2 ? 'flex' : 'none', }} className='img-blocks'>
                            {this.props.generatedImages.length > 0 && !this.props.loadingAIImages && this.props.generatedImages.map(x =>
                                <img
                                    onClick={() => this.setState({ selectImg: x, selectedImg: true })}
                                    src={x}
                                    style={{ width: '10vw', height: '15vw', marginLeft: '92px' }}
                                />
                            )}
                            {(this.props.generatedImages.length === 0 || this.props.loadingAIImages) && (
                                <>
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '10vw', height: '15vw', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '10vw', height: '15vw', marginLeft: '92px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '10vw', height: '15vw', marginLeft: '92px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '10vw', height: '15vw', marginLeft: '92px', backgroundColor: '#C1D0CE' }} />
                                </>
                            )}
                        </div>
                        <div style={{ display: this.state.selectedImg ? 'none' : this.state.aspectRatio === 3 ? 'flex' : 'none', }} className='img-blocks'>
                            {this.props.generatedImages.length > 0 && !this.props.loadingAIImages && this.props.generatedImages.map(x =>
                                <img
                                    onClick={() => this.setState({ selectImg: x, selectedImg: true })}
                                    src={x}
                                    style={{ width: '15vw', height: '10vw', marginLeft: '16px' }}
                                />
                            )}
                            {(this.props.generatedImages.length === 0 || this.props.loadingAIImages) && (
                                <>
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '10vw', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '10vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '10vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                    <div className={this.props.loadingAIImages && 'img-blocks-skeleton'} style={{ width: '15vw', height: '10vw', marginLeft: '16px', backgroundColor: '#C1D0CE' }} />
                                </>
                            )}
                        </div>
                        <div style={{ width: '50vw', height: '75vh', paddingTop: '10vh' }}>
                            {this.state.selectedImg &&
                                <FilerobotImageEditor
                                    source={this.state.selectedImg ? this.state.selectImg : "https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"}
                                    onSave={(editedImageObject, designState) => this.saveImage(editedImageObject.imageBase64)}
                                    onClose={() => this.setState({ selectedImg: false })}

                                    annotationsCommon={{
                                        fill: '#ff0000'
                                    }}
                                    Text={{ text: 'Filerobot...' }}
                                    Rotate={{ angle: 90, componentType: 'slider' }}
                                    tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
                                    defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                                    defaultToolId={TOOLS.TEXT} // or 'Text'
                                />}

                        </div>
                        <div style={{ display: this.state.selectedImg ? '' : 'none' }} onClick={() => this.applyImg()} className='apply-ai-text'><p>Apply to layout</p></div>

                        <div id="loading" style={{ display: this.props.loadingAIImages ? '' : 'none', width: '100%', height: 'calc(100vh + 69px)', backgroundColor: 'transparent', position: 'absolute', top: '-62px', left: '-376px', zIndex: '1001' }} >
                            {/*  <Reveal when={this.props.loadingAIImages} effect="fadeInUp">
                                <div style={{ position: 'absolute', top: '50%', right: '50%', transform: "translate(50%,-50%)" }}>  <img src={icons.loading} /></div>
                            </Reveal>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setArr: setArr, setAiImages: setAiImages, setAIImgLoading: setAIImgLoading }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        token: state.token,
        loadingAIImages: state.loadingAIImages,
        generatedImages: state.generatedImages,
        selectedElm: state.selectedElm
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(AiImg);