import { Container } from '@react-email/container';

import React, { Component } from 'react';
import ImageBlock from './imageBlock';
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import Menu from '../../../components/Menu';

import { bindActionCreators } from 'redux';
import generateBlock from './generateBlock';
import padding from '../attrBlocks/padding';

class AIHeader extends Component {

    constructor(props) {
        super(props);
        this.types = ['ai-header-1', 'ai-header-2', 'ai-header-3', 'ai-header-4', 'ai-header-5', 'ai-header-6'];
        this.state = {

        };
    }

    getImg = () => {

        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return generateBlock(arr[i]);
            }
        }
    }
    getText = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return generateBlock(arr[i]);
            }
        }
    }
    getText2 = () => {
        var arr = this.props.arr;
        var header = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                if (header === false) {
                    header = true;
                    continue;
                } else {
                    return generateBlock(arr[i]);
                }

            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getBackgroundColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: this.props.type })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const blockChildrens = this.props.arr.filter(item => item.params.for === block[0].id);
        const modifiedblockChildrens = blockChildrens.map(item => {
            return { ...item, id: this.uuidv4(), params: { ...item.params, for: id } }
        })
        let childrens = [];
        modifiedblockChildrens.forEach(item => childrens.push(item.id))
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id, childrens: [...childrens] }
        }))
        const res = [...modifiedblockChildrens, ...modifiedBlock];
        this.props.arr.push(...res);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);

    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    componentDidMount = () => {
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom');

        var arr = JSON.parse(JSON.stringify(this.props.arr));
        arr = this.switchStyles(this.getType(), arr);
        this.props.setArr(arr);
    }

    componentWillUnmount = () => {
        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }
    getborderTopLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopLeftRadius;
            }
        }
    }
    getborderBottomLeftRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomLeftRadius;
            }
        }
    }
    getborderTopRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderTopRightRadius;
            }
        }
    }
    getborderBottomRightRadius = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.borderBottomRightRadius;
            }
        }
    }
    getIWidth = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.width;
            }
        }
    }
    getIBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getTBackground = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return arr[i].params.backgroundColor;
            }
        }
    }
    getAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.align;
            }
        }
    }
    getIAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                return arr[i].params.align;
            }
        }
    }
    getTAlign = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                return arr[i].params.align;
            }
        }
    }
    getDir = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {

                return arr[i].params.dir;
            }
        }
    }
    getTrueWidth = () => {
        var width = '602px';
        var arr = this.props.arr;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === '-122') {

                var params = arr[i].params;
                var pR = parseInt(params.paddingRight);
                var pL = parseInt(params.paddingLeft);

                width = 602 - pR - pL;
                width = width.toString() + 'px';
                break;
            }
        }
        return width;
    }
    getType = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].type;
            }
        }
    }
    styleBack = () => {
        let type = this.getType();
        let num = parseInt(type.replace('ai-header-', ''));
        if (num <= 1) {
            return;
        } else {
            num--;
            type = 'ai-header-' + num.toString();
            var arr = JSON.parse(JSON.stringify(this.props.arr));
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id == this.props.id) {
                    arr[i].type = type;
                    arr = this.switchStyles(type, arr);
                    this.props.setArr(arr);
                }
            }
        }
    }
    setBackgroundT = (color, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                arr[i].params.backgroundColor = color;


            }
        }
        return arr;
    }
    setColorT = (color, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                arr[i].params.color = color;

            }
        }
        return arr;
    }
    setIWidth = (width, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                arr[i].params.width = width;

            }
        }
        return arr;
    }
    setIPadding = (padding, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                arr[i].params.paddingTop = padding.split(',')[0];
                arr[i].params.paddingLeft = padding.split(',')[1];
                arr[i].params.paddingBottom = padding.split(',')[2];
                arr[i].params.paddingRight = padding.split(',')[3];

            }
        }
        return arr;
    }
    setT1Padding = (padding, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {
                arr[i].params.paddingTop = padding.split(',')[0];
                arr[i].params.paddingLeft = padding.split(',')[1];
                arr[i].params.paddingBottom = padding.split(',')[2];
                arr[i].params.paddingRight = padding.split(',')[3];
                break;

            }
        }
        return arr;
    }
    setT2Padding = (padding, arr) => {
        var first = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "text" && first === false) {
                first = true;
                continue;
            }
            if (arr[i].params.for === this.props.id && arr[i].type === "text" && first === true) {
                arr[i].params.paddingTop = padding.split(',')[0];
                arr[i].params.paddingLeft = padding.split(',')[1];
                arr[i].params.paddingBottom = padding.split(',')[2];
                arr[i].params.paddingRight = padding.split(',')[3];
                break;

            }
        }
        return arr;
    }
    renderBlock = (type) => {

        switch (type) {
            case 'ai-header-1':
                return <tr style={{
                    display: 'block', marginTop: this.getMarginTop(), marginLeft: this.getMarginLeft(), marginRight: this.getMarginRight(),
                    marginBottom: this.getMarginBottom()
                }}>
                    <td align={this.getIAlign()} style={{
                        borderTopLeftRadius: this.getborderTopLeftRadius(),
                        borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                        verticalAlign: 'top', paddingTop: this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                        paddingBottom: this.getPaddingBottom(),
                        width: this.getIWidth(),
                        backgroundColor: this.getIBackground()
                    }}>{this.getImg()}</td>
                    <td align={this.getTAlign()} style={{
                        borderTopRightRadius: this.getborderTopRightRadius(),
                        width: '100%',
                        borderBottomRightRadius: this.getborderBottomRightRadius(),
                        backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                        paddingBottom: this.getPaddingBottom(),
                    }}>
                        {this.getText()}
                        {this.getText2()}
                    </td>

                </tr>;
                break;
            case 'ai-header-2':
                return <tr style={{
                    display: 'block', marginTop: this.getMarginTop(), marginLeft: this.getMarginLeft(), marginRight: this.getMarginRight(),
                    marginBottom: this.getMarginBottom()
                }}>

                    <td align={this.getTAlign()} style={{
                        borderTopRightRadius: this.getborderTopRightRadius(),
                        width: '100%',
                        borderBottomRightRadius: this.getborderBottomRightRadius(),
                        backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                        paddingBottom: this.getPaddingBottom(),
                    }}>
                        {this.getText()}
                        {this.getText2()}
                    </td>
                    <td align={this.getIAlign()} style={{
                        borderTopLeftRadius: this.getborderTopLeftRadius(),
                        borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                        verticalAlign: 'top', paddingTop: this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                        paddingBottom: this.getPaddingBottom(),
                        width: this.getIWidth(),
                        backgroundColor: this.getIBackground()
                    }}>{this.getImg()}</td>

                </tr>;
                break;
            case 'ai-header-3':
                return <tbody>
                    <tr>
                        <td align={this.getIAlign()} style={{
                            borderTopLeftRadius: this.getborderTopLeftRadius(),
                            borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                            verticalAlign: 'top',
                            width: this.getIWidth(),
                            backgroundColor: this.getIBackground()
                        }}>{this.getImg()}</td>
                    </tr>
                    <tr>
                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.getPaddingBottom(),
                        }}>
                            {this.getText()}
                            {this.getText2()}
                        </td>
                    </tr>
                </tbody>;
                break;
            case 'ai-header-4':
                return <tr style={{
                    display: 'block', marginTop: this.getMarginTop(), marginLeft: this.getMarginLeft(), marginRight: this.getMarginRight(),
                    marginBottom: this.getMarginBottom()
                }}>

                    <td align={this.getTAlign()} style={{
                        borderTopRightRadius: this.getborderTopRightRadius(),
                        width: '100%',
                        borderBottomRightRadius: this.getborderBottomRightRadius(),
                        backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                        paddingBottom: this.getPaddingBottom(),
                    }}>
                        {this.getText()}
                        {this.getText2()}
                    </td>
                    <td align={this.getIAlign()} style={{
                        borderTopLeftRadius: this.getborderTopLeftRadius(),
                        borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                        verticalAlign: 'top', paddingTop: this.getPaddingTop(), paddingLeft: this.getPaddingLeft(),
                        paddingBottom: this.getPaddingBottom(),
                        width: this.getIWidth(),
                        backgroundColor: this.getIBackground()
                    }}>{this.getImg()}</td>

                </tr>;
                break;
            case 'ai-header-5':
                return <tbody>
                    <tr>
                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.getPaddingBottom(),
                        }}>

                            {this.getText()}
                        </td>
                    </tr>
                    <tr>
                        <td align={this.getIAlign()} style={{
                            borderTopLeftRadius: this.getborderTopLeftRadius(),
                            borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                            verticalAlign: 'top',
                            width: this.getIWidth(),
                            backgroundColor: this.getIBackground()
                        }}>{this.getImg()}</td>
                    </tr>
                    <tr>
                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.getPaddingBottom(),
                        }}>

                            {this.getText2()}
                        </td>
                    </tr>
                </tbody>;
            case 'ai-header-6':
                return <tbody>
                    <tr>
                        <td align={this.getTAlign()} style={{
                            borderTopRightRadius: this.getborderTopRightRadius(),
                            width: '100%',
                            borderBottomRightRadius: this.getborderBottomRightRadius(),
                            backgroundColor: this.getTBackground(), paddingTop: this.getPaddingTop(), paddingRight: this.getPaddingRight(),
                            paddingBottom: this.getPaddingBottom(),
                        }}>
                            {this.getText()}
                            {this.getText2()}
                        </td>
                    </tr>
                    <tr>
                        <td align={this.getIAlign()} style={{
                            borderTopLeftRadius: this.getborderTopLeftRadius(),
                            borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                            verticalAlign: 'top',
                            width: this.getIWidth(),
                            backgroundColor: this.getIBackground()
                        }}>{this.getImg()}</td>
                    </tr>
                </tbody>;
                break;
                break;
            default:
                break;
        }
    }
    setImg = (src, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].params.for === this.props.id && arr[i].type === "image") {
                arr[i].params.src = src;

            }
        }
        return arr;
    }
    switchStyles = (type, arr) => {

        switch (type) {
            case 'ai-header-1':
                arr = this.setBackgroundT('white', arr);
                arr = this.setColorT('black', arr);
                arr = this.setImg('https://adbotest.digitum.nl/static/media/header_1.png', arr);
                arr = this.setIWidth('220px', arr);
                arr = this.setIPadding('0px,0px,0px,0px', arr);
                arr = this.setT1Padding('20px,20px,12px,20px', arr);
                arr = this.setT2Padding('0px,20px,0px,20px', arr);
                break;
            case 'ai-header-2':
                arr = this.setBackgroundT('#C1F926', arr);
                arr = this.setColorT('black', arr);
                arr = this.setImg('https://adbotest.digitum.nl/static/media/header_1.png', arr);
                arr = this.setIWidth('220px', arr);
                arr = this.setIPadding('0px,0px,0px,0px', arr);
                arr = this.setT1Padding('40px,20px,12px,20px', arr);
                arr = this.setT2Padding('0px,20px,40px,20px', arr);
                break;
            case 'ai-header-3':
                arr = this.setBackgroundT('#0368E7', arr);
                arr = this.setColorT('white', arr);
                arr = this.setIWidth('600px', arr);
                arr = this.setImg('https://adbotest.digitum.nl/static/media/header_2.png', arr);
                arr = this.setIPadding('0px,0px,0px,0px', arr);
                arr = this.setT1Padding('40px,20px,12px,20px', arr);
                arr = this.setT2Padding('0px,20px,40px,20px', arr);
                break;
            case 'ai-header-4':
                arr = this.setBackgroundT('white', arr);
                arr = this.setColorT('black', arr);
                arr = this.setIWidth('188px', arr);
                arr = this.setImg('https://adbotest.digitum.nl/static/media/header_3.png', arr);
                arr = this.setIPadding('40px,0px,40px,0px', arr);
                arr = this.setT1Padding('40px,20px,12px,20px', arr);
                arr = this.setT2Padding('0px,20px,40px,20px', arr);
                break;
            case 'ai-header-5':
                arr = this.setBackgroundT('#0368E7', arr);
                arr = this.setColorT('white', arr);
                arr = this.setIWidth('600px', arr);
                arr = this.setImg('https://adbotest.digitum.nl/static/media/header_2.png', arr);
                arr = this.setIPadding('0px,0px,0px,0px', arr);
                arr = this.setT1Padding('40px,20px,32px,20px', arr);
                arr = this.setT2Padding('32px,20px,40px,20px', arr);
                break;
            case 'ai-header-6':
                arr = this.setBackgroundT('#C1F926', arr);
                arr = this.setColorT('black', arr);
                arr = this.setIWidth('600px', arr);
                arr = this.setImg('https://adbotest.digitum.nl/static/media/header_2.png', arr);
                arr = this.setIPadding('0px,0px,0px,0px', arr);
                arr = this.setT1Padding('40px,20px,12px,20px', arr);
                arr = this.setT2Padding('0px,20px,40px,20px', arr);
                break;
            default:
                break;
        }
        return arr;
    }
    styleForward = () => {
        let type = this.getType();
        let num = parseInt(type.replace('ai-header-', ''));
        if (num >= this.types.length) {
            return;
        } else {
            num++;
            type = 'ai-header-' + num.toString();
            var arr = JSON.parse(JSON.stringify(this.props.arr));
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id == this.props.id) {
                    arr[i].type = type;
                    arr = this.switchStyles(type, arr);
                    this.props.setArr(arr);
                }
            }
        }
    }
    render() {
        return (
            <table name={this.props.id} onMouseEnter={(e) => {
                var btns = document.getElementsByName(this.props.id)[0].getElementsByClassName('change-buttonts')[0];
                btns.style.visibility = '';
                btns.style.opacity = 1;
            }} onMouseLeave={(e) =>{
                var btns = document.getElementsByName(this.props.id)[0].getElementsByClassName('change-buttonts')[0];
                btns.style.visibility = 'hidden';
                btns.style.opacity = 0;
            }} data-content='List' id='moved-block' className='higlighted__parentElem' width="602px" cellspacing="0" align={this.getAlign()} cellpadding="0" style={{
                maxWidth: this.getTrueWidth(), width: this.getTrueWidth(),
                border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                borderTopLeftRadius: this.getborderTopLeftRadius(),
                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                borderTopRightRadius: this.getborderTopRightRadius(),
                borderBottomRightRadius: this.getborderBottomRightRadius(),


                backgroundColor: this.getBackgroundColor()
            }} onClick={(e) => e.target.tagName === "TD" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>

                {this.renderBlock(this.getType())}
                <div className='change-buttonts' style={{ position: 'absolute', zIndex: 1, top: '8px', right: 32, visibility: 'hidden', opacity: '0', transition: '0.3s all'}}>
                    <img onClick={() => this.styleBack()} Style="cursor: pointer;background: #e6e6e68a;border-radius: 100%;margin-right: 16px;" src="https://adbotest.digitum.nl/static/media/ai_back.svg" />
                    <img onClick={() => this.styleForward()} Style="cursor: pointer;background: #e6e6e68a;border-radius: 100%;" src="https://adbotest.digitum.nl/static/media/ai_forward.svg" />
                </div>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </table>
        );
    }

}
/*

<Section data-content='List' id='moved-block' className='higlighted__parentElem' style={{
                paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                borderTopLeftRadius: this.getborderTopLeftRadius(),
                borderBottomLeftRadius: this.getborderBottomLeftRadius(),
                borderTopRightRadius: this.getborderTopRightRadius(),
                borderBottomRightRadius: this.getborderBottomRightRadius(),
                backgroundColor: this.getBackgroundColor(),
                marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                marginRight: this.getMarginRight(),
            }} onClick={(e) => e.target.tagName === "TD" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>
                <Column style={{ verticalAlign: 'top' }}>{this.getImg()}</Column>
                <Column>{this.getText()}</Column>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </Section>
*/

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(AIHeader);