import { render } from '@react-email/render';
import { Html } from '@react-email/html';
import { Body } from '@react-email/body';

import { getTextBlock } from '../pages/Constructor/renderBlocks/textBlock';
import { getImgBlock } from '../pages/Constructor/renderBlocks/imageBlock';
import { getImgButton } from '../pages/Constructor/renderBlocks/imageButton';
import { getListBlock } from '../pages/Constructor/renderBlocks/listBlock';
import { getLogoBlock } from '../pages/Constructor/renderBlocks/logoBlock';
import { getSocialBlock } from '../pages/Constructor/renderBlocks/socialBlock';
import { getTextLinkBlock } from '../pages/Constructor/renderBlocks/textLinkBlock';
import { getThreeIRow } from '../pages/Constructor/renderBlocks/three_i_row';
import { getTextTwoBlock } from '../pages/Constructor/renderBlocks/text_two_block';
import { getTextThreeBlock } from '../pages/Constructor/renderBlocks/text_three_block';
import { getTextImage } from '../pages/Constructor/renderBlocks/textImage';
import { getTwoTextImage } from '../pages/Constructor/renderBlocks/two_text_image';
import { getThreeTextImage } from '../pages/Constructor/renderBlocks/three_text_image';
import { getAiLogoBlock } from '../pages/Constructor/renderBlocks/ai_logo';
import { getAiHeaderBlock } from '../pages/Constructor/renderBlocks/ai_header';
import { getAiListBlock } from '../pages/Constructor/renderBlocks/ai_list';
import { getAiOffersBlock } from '../pages/Constructor/renderBlocks/ai_offers';
import { renderToStaticMarkup } from 'react-dom/server';
import { getAiFooterBlock } from '../pages/Constructor/renderBlocks/ai_footer';
export const getHtmlRaw = (array) => {
    var arr = [];
    var p_arr = array;
    var main = {};
    for (var i = 0; i < p_arr.length; i++) {
        if (p_arr[i].id === '-122') {
            main = p_arr[i].params;
            break;
        }
    }

    for (var i = 0; i < p_arr.length; i++) {
        var item = p_arr[i];
        var params = item.params;
        if (params.color !== undefined) {
            params.color = getCorrectColor(params.color);
        }
        if (params.backgroundColor !== undefined) {
            params.backgroundColor = getCorrectColor(params.backgroundColor);
        }


        if (item.type === "text" && item.params.for === -99) {
            arr.push(<tr><td>{getTextBlock(params, main)}</td></tr>);
        } else if (item.type === "image" && item.params.for === -99) {
            arr.push(<tr><td>{getImgBlock(params)}</td></tr>);
        } else if (item.type === "link-img" && item.params.for === -99) {
            arr.push(<tr><td>{getImgButton(params)}</td></tr>);
        } else if (item.type === "list-block" && item.params.for === -99) {
            var __arr = array;
            var img = '';
            var text = '';
            for (var j = 0; j < __arr.length; j++) {
                if (__arr[j].params.for === item.id && __arr[j].type === "image") {
                    img = __arr[j];
                }
                if (__arr[j].params.for === item.id && __arr[j].type === "text") {
                    text = __arr[j];
                }
            }

            arr.push(<tr><td>{getListBlock(img, text, params, main)}</td></tr>);
        }
        else if (item.type.indexOf('ai-logo') !== -1 && item.params.for === -99) {
            var __arr = array;
            var img1 = '';
            var img2 = '';
            var img3 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    img1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    img2 = __arr[j];
                }
                if (__arr[j].id === item.childrens[2]) {
                    img3 = __arr[j];
                }
            }
            arr.push(<tr><td>{getAiLogoBlock(img1, img2, img3, params, item.type)}</td></tr>);

        }
        else if (item.type.indexOf('ai-header') !== -1 && item.params.for === -99) {
            var __arr = array;
            var img = '';
            var text = '';
            var text2 = '';
            for (var j = 0; j < __arr.length; j++) {
                if (__arr[j].params.for === item.id && __arr[j].type === "image") {
                    img = __arr[j];
                }
                if (__arr[j].params.for === item.id && __arr[j].type === "text" && text === '') {
                    text = __arr[j];
                }
                if (__arr[j].params.for === item.id && __arr[j].type === "text" && text !== __arr[j]) {
                    text2 = __arr[j];
                }

            }

            arr.push(<tr><td>{getAiHeaderBlock(img, text, text2, params, main, item.type)}</td></tr>);
        }
        else if (item.type.indexOf('ai-list') !== -1 && item.params.for === -99) {
            var __arr = array;
            var params_t = '';
            var elemts = [];
            for (var j = 0; j < __arr.length; j++) {
                if (__arr[j].params.for === item.id && params_t === '' && __arr[j].type === 'text') {
                    params_t = __arr[j].params;
                }
                if (__arr[j].params.for === item.id && __arr[j].type !== 'text') {
                    var img = '';
                    var text = '';
                    for (var x = 0; x < __arr.length; x++) {
                        if (__arr[x].params.for === __arr[j].id && __arr[x].type === 'text') {
                            text = __arr[x];
                        }
                        if (__arr[x].params.for === __arr[j].id && __arr[x].type === 'image') {
                            img = __arr[x];
                        }

                    }

                    elemts.push({ main: __arr[j], text: text, img: img });
                }
            }

            arr.push(<tr><td>{getAiListBlock(params, params_t, elemts, item.type, __arr)}</td></tr>);
        }
        else if (item.type.indexOf('ai-offers') !== -1 && item.params.for === -99) {
            var __arr = array;
            var elemts = [];
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].params.for === item.id && __arr[j].type !== 'text') {
                    var img = '';
                    var text = '';
                    for (var x = 0; x < __arr.length; x++) {
                        if (__arr[x].params.for === __arr[j].id && __arr[x].type === 'text') {
                            text = __arr[x];
                        }
                        if (__arr[x].params.for === __arr[j].id && __arr[x].type === 'image') {
                            img = __arr[x];
                        }

                    }

                    elemts.push({ main: __arr[j], text: text, img: img });
                }
            }
            arr.push(<tr><td>{getAiOffersBlock(params, elemts, item, __arr)}</td></tr>);
        }
        else if (item.type.indexOf('ai-footer') !== -1 && item.params.for === -99){
            var __arr = array;

            var txt1 = '';
            var txt2 = '';
            for(var j = 0; j < __arr.length; j++){
                if (__arr[j].params.for === item.id && txt1 === ''){
                    txt1 = __arr[j].params;
                    continue;
                }
                if (__arr[j].params.for === item.id && txt1 !== ''){
                    txt2 = __arr[j].params;
                    break;
                }
            }
            arr.push(<tr><td>{getAiFooterBlock(txt1,txt2, item.params, item.type)}</td></tr>);
        }
        else if (item.type === "logo-block" && item.params.for === -99) {
            var __arr = array;
            var img1 = '';
            var img2 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    img1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    img2 = __arr[j];
                }
            }
            arr.push(<tr><td>{getLogoBlock(img1, img2, params)}</td></tr>);

        }
        else if (item.type === "three-i-row" && item.params.for === -99) {
            var __arr = array;
            var img1 = '';
            var img2 = '';
            var img3 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    img1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    img2 = __arr[j];
                }
                if (__arr[j].id === item.childrens[2]) {
                    img3 = __arr[j];
                }
            }
            arr.push(<tr><td>{getThreeIRow(img1, img2, img3, params)}</td></tr>);

        }
        else if (item.type === 'text-image' && item.params.for === -99) {
            var __arr = array;
            var img = '';
            var text = '';
            for (var j = 0; j < __arr.length; j++) {
                if (__arr[j].params.for === item.id && __arr[j].type === "image") {
                    img = __arr[j];
                }
                if (__arr[j].params.for === item.id && __arr[j].type === "text") {
                    text = __arr[j];
                }
            }

            arr.push(<tr><td>{getTextImage(img, text, params, main, null)}</td></tr>);
        }
        else if (item.type === "two-text-image" && item.params.for === -99) {
            var __arr = array;

            var data_arr = [];

            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    var dat = {
                        img: null,
                        text: null,
                        params: __arr[j].params
                    };

                    for (var y = 0; y < __arr.length; y++) {
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "image") {
                            dat.img = __arr[y];
                        }
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "text") {
                            dat.text = __arr[y];
                        }
                    }
                    data_arr.push(dat);

                }
                if (__arr[j].id === item.childrens[1]) {
                    var dat = {
                        img: null,
                        text: null,
                        params: __arr[j].params
                    };
                    for (var y = 0; y < __arr.length; y++) {
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "image") {
                            dat.img = __arr[y];
                        }
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "text") {
                            dat.text = __arr[y];
                        }
                    }
                    data_arr.push(dat);
                }
            }
            arr.push(<tr><td>{getTwoTextImage(data_arr, params, main, array)}</td></tr>);

        }
        else if (item.type === "three-text-image" && item.params.for === -99) {
            var __arr = array;

            var data_arr = [];

            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    var dat = {
                        img: null,
                        text: null,
                        params: __arr[j].params
                    };

                    for (var y = 0; y < __arr.length; y++) {
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "image") {
                            dat.img = __arr[y];
                        }
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "text") {
                            dat.text = __arr[y];
                        }
                    }
                    data_arr.push(dat);

                }
                if (__arr[j].id === item.childrens[1]) {
                    var dat = {
                        img: null,
                        text: null,
                        params: __arr[j].params
                    };
                    for (var y = 0; y < __arr.length; y++) {
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "image") {
                            dat.img = __arr[y];
                        }
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "text") {
                            dat.text = __arr[y];
                        }
                    }
                    data_arr.push(dat);
                }
                if (__arr[j].id === item.childrens[2]) {
                    var dat = {
                        img: null,
                        text: null,
                        params: __arr[j].params
                    };
                    for (var y = 0; y < __arr.length; y++) {
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "image") {
                            dat.img = __arr[y];
                        }
                        if (__arr[y].params.for === __arr[j].id && __arr[y].type === "text") {
                            dat.text = __arr[y];
                        }
                    }
                    data_arr.push(dat);
                }
            }
            arr.push(<tr><td>{getThreeTextImage(data_arr, params, main, array)}</td></tr>);

        }
        else if (item.type === "text-two-block" && item.params.for === -99) {
            var __arr = array;
            var img1 = '';
            var img2 = '';
            var img3 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    img1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    img2 = __arr[j];
                }
            }
            arr.push(<tr><td>{getTextTwoBlock(img1, img2, params)}</td></tr>);

        }
        else if (item.type === "text-three-block" && item.params.for === -99) {
            var __arr = array;
            var img1 = '';
            var img2 = '';
            var img3 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    img1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    img2 = __arr[j];
                }
                if (__arr[j].id === item.childrens[2]) {
                    img3 = __arr[j];
                }
            }
            arr.push(<tr><td>{getTextThreeBlock(img1, img2, img3, params)}</td></tr>);

        }
        else if (item.type === "social-block" && item.params.for === -99) {
            var __arr = array;
            var block1 = '';
            var block2 = '';
            var block3 = '';
            var block4 = '';
            for (var j = 0; j < __arr.length; j++) {

                if (__arr[j].id === item.childrens[0]) {
                    block1 = __arr[j];
                }
                if (__arr[j].id === item.childrens[1]) {
                    block2 = __arr[j];
                }
                if (__arr[j].id === item.childrens[2]) {
                    block3 = __arr[j];
                }
                if (__arr[j].id === item.childrens[3]) {
                    block4 = __arr[j];
                }
            }
            arr.push(<tr><td>{getSocialBlock(block1, block2, block3, block4, params)}</td></tr>);
        } else if (item.type === "text-link" && item.params.for === -99) {
            arr.push(<tr><td>{getTextLinkBlock(params)}</td></tr>);
        }
    }
    var mainBlock = array.filter(x => x.id === '-122');
    var obj = {
        backgroundColor: '#ffffff',
        paddingTop: '0',
        paddingBottom: '0',
        paddingLeft: '0',
        paddingRight: '0'
    };
    if (mainBlock.length > 0) {
        obj = mainBlock[0].params;
    }
    var style = '<!--[if mso]><style>.text-block{padding-top: 5%;}</style><![endif]-->';
    const html = render(<Html><head dangerouslySetInnerHTML={{__html: style}}></head><Body style={{ padding: '0', margin: '0' }}>
        <table width="600" cellspacing="0"
            style={{
            //    border: '1px solid red',
                paddingTop: obj.paddingTop,
                paddingBottom: obj.paddingBottom,
                paddingLeft: obj.paddingLeft,
                paddingRight: obj.paddingRight,
                backgroundColor: obj.backgroundColor
            }}
            cellpadding="0" border="0" bgcolor={obj.backgroundColor} align="center">
            <tbody>
                {arr.map(x => x)}
            </tbody>
        </table>
    </Body></Html>, { pretty: true });
    return html;
}
function getCorrectColor(raw) {
    if (raw !== '#ffffff') {
        if (raw.substr(raw.length - 2) === 'ff' && raw.length > 7) {
            return raw.substr(0, raw.length - 2);
        } else {
            return raw;
        }
    } else {
        return raw;
    }
}