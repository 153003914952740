import { Container } from '@react-email/container';

import React, { Component } from 'react';
import ImageBlock from './imageBlock';
import { Column } from '@react-email/column';
import TestTextBlock from './textBlock';
import { Section } from '@react-email/section';
import { connect } from 'react-redux';
import { putElm, setArr } from '../../../actions/actions';
import Menu from '../../../components/Menu';

import { bindActionCreators } from 'redux';
import { Row } from '@react-email/row';
import generateBlock from './generateBlock';

class AIFooter extends Component {

    constructor(props) {
        super(props);
        this.types = ['ai-footer-1', 'ai-footer-2', 'ai-footer-3', 'ai-footer-4', 'ai-footer-5', 'ai-footer-6', 'ai-footer-7', 'ai-footer-8', 'ai-footer-9'];
        this.state = {

        };
    }

    getText1 = () => {

        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text1) {
                return generateBlock(arr[i]);
            }
        }
    }
    getText1Align = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text1) {
                return arr[i].params.align;
            }
        }
    }
    getText2Align = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text2) {
                return arr[i].params.align;
            }
        }
    }
    getText2 = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.text2) {
                return generateBlock(arr[i]);
            }
        }
    }
    getMarginTop = () => {
        var arr = this.props.arr;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginTop;
            }
        }
    }
    getMarginLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginLeft;
            }
        }
    }
    getMarginRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginRight;
            }
        }
    }
    getMarginBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.marginBottom;
            }
        }
    }
    getPaddingTop = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingTop;
            }
        }
    }
    getPaddingLeft = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingLeft;
            }
        }
    }
    getPaddingRight = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingRight;
            }
        }
    }
    getPaddingBottom = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.paddingBottom;
            }
        }
    }
    getBackgroundColor = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].params.backgroundColor;
            }
        }
    }

    checkIfCanInteract = () => {
        return this.props.arr.filter(item => item.id === this.props.id)[0].params.for === -99;
    }

    editBlock = () => {
        this.props.putElm({ id: this.props.id, type: this.props.type })
    }

    deleteBlock = () => {
        const deletedBlock = this.props.arr.filter(item => item.id === this.props.id);
        const childrens = deletedBlock[0].childrens;
        if (deletedBlock[0].params.for !== -99) return;
        this.props.setArr(this.props.arr.filter(item => item.id !== this.props.id).filter(item => {
            if (!childrens.includes(item.id)) {
                return item
            }
        }));
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    copyBlock = () => {
        let id = this.uuidv4();
        const block = this.props.arr.filter(item => item.id === this.props.id);
        const blockChildrens = this.props.arr.filter(item => item.params.for === block[0].id);
        const modifiedblockChildrens = blockChildrens.map(item => {
            return { ...item, id: this.uuidv4(), params: { ...item.params, for: id } }
        })
        let childrens = [];
        modifiedblockChildrens.forEach(item => childrens.push(item.id))
        const modifiedBlock = structuredClone(block.map(item => {
            return { ...item, id: id, childrens: [...childrens] }
        }))
        const res = [...modifiedblockChildrens, ...modifiedBlock];
        this.props.arr.push(...res);
        var arr = Array.prototype.concat(this.props.arr);
        this.props.setArr(arr);
    }

    elemMove = (direction) => {
        let arr = this.props.arr.filter(elem => elem.params.for === -99);
        let arrWithout99 = this.props.arr.filter(elem => elem.params.for !== -99);
        let selectedElemIndex = 0;
        let selectedElem = arr.find((elem, index) => {
            selectedElemIndex = index;
            return elem.id === this.props.id
        });
        let res = [];
        if (direction === 'up') {
            if (selectedElemIndex === 0) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex - 1, 0, element);
            res = arrCopy;
        }
        if (direction === 'down') {
            if (selectedElemIndex === this.props.arr.length - 1) return;
            const arrCopy = [...arr];
            let element = arrCopy[selectedElemIndex];
            arrCopy.splice(selectedElemIndex, 1);
            arrCopy.splice(selectedElemIndex + 1, 0, element);
            res = arrCopy;
        }
        this.props.setArr(res.concat(arrWithout99));
    }

    componentDidMount = () => {
        document.querySelectorAll('#moved-block')[0].classList.add('menu-moveBottom');

        var arr = JSON.parse(JSON.stringify(this.props.arr));
        arr = this.switchStyles(this.getType(), arr);
        this.props.setArr(arr);
    }

    componentWillUnmount = () => {

        document.querySelectorAll('#moved-block')[0].classList.remove('menu-moveBottom')
    }
    getType = () => {
        var arr = this.props.arr;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id == this.props.id) {
                return arr[i].type;
            }
        }
    }
    styleBack = () => {
        let type = this.getType();
        let num = parseInt(type.replace('ai-footer-', ''));
        if (num <= 1) {
            return;
        } else {
            num--;
            type = 'ai-footer-' + num.toString();
            var arr = JSON.parse(JSON.stringify(this.props.arr));
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id == this.props.id) {
                    arr[i].type = type;
                    arr = this.switchStyles(type, arr);
                    this.props.setArr(arr);
                }
            }
        }
    }
    styleForward = () => {
        let type = this.getType();
        let num = parseInt(type.replace('ai-footer-', ''));
        if (num >= this.types.length) {
            return;
        } else {
            num++;
            type = 'ai-footer-' + num.toString();
            var arr = JSON.parse(JSON.stringify(this.props.arr));
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id == this.props.id) {
                    arr[i].type = type;
                    arr = this.switchStyles(type, arr);
                    this.props.setArr(arr);
                }
            }
        }
    }
    setBackgroundT = (color, tColor, fontWeight, arr) => {

        let main = false;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].id === this.props.id) {
                arr[i].params.backgroundColor = color;
            }
            if (arr[i].params.for === this.props.id && arr[i].type === "text") {

                arr[i].params.backgroundColor = color;
                arr[i].params.color = tColor;
                if (main === true) {
                    arr[i].params.fontWeight = fontWeight;
                }

                if (main === false) {
                    main = true;
                }

            }
        }
        return arr;
    }
    switchStyles = (type, arr) => {
        console.log(arr);
        switch (type) {
            case 'ai-footer-1':
                arr = this.setBackgroundT('#0368E7', 'white', '400', arr);
                break;
            case 'ai-footer-2':
                arr = this.setBackgroundT('white', 'black', '400', arr);
                break;
            case 'ai-footer-3':
                arr = this.setBackgroundT('#C1F926', 'black', '400', arr);
                break;
            case 'ai-footer-4':
                arr = this.setBackgroundT('black', 'white', '400', arr);
                break;
            case 'ai-footer-5':
                arr = this.setBackgroundT('#0368E7', 'white', '400', arr);
                break;
            case 'ai-footer-6':
                arr = this.setBackgroundT('white', 'black', '700', arr);
                break;
            case 'ai-footer-7':
                arr = this.setBackgroundT('#D5E6FB', 'black', '400', arr);
                break;
            case 'ai-footer-8':
                arr = this.setBackgroundT('#E9FEB0', 'black', '400', arr);
                break;
            case 'ai-footer-9':
                arr = this.setBackgroundT('#EFEFEF', 'black', '400', arr);
                break;
            default:
                break;

        }
        console.error(arr);
        return arr;
    }
    renderBlock = (type) => {

        switch (type) {
            case 'ai-footer-1':

                return <>
                    <Column width={300} style={{ verticalAlign: 'top' }} align={this.getText1Align()}>{this.getText1()}</Column>
                    <Column align={this.getText2Align()}>{this.getText2()}</Column>
                </>;
                break;
            case 'ai-footer-2':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-3':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-4':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-5':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-6':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-7':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-8':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            case 'ai-footer-9':

                return <>
                    <tr>
                        <td align={this.getText1Align()}>
                            {this.getText1()}
                        </td>

                    </tr>
                    <tr>
                        <td align={this.getText2Align()}>
                            {this.getText2()}
                        </td>
                    </tr>
                </>
                break;
            default:
                break;
        }
    }
    render() {
        return (
            <Row
                name={this.props.id} onMouseEnter={(e) => {
                    var btns = document.getElementsByName(this.props.id)[0].getElementsByClassName('change-buttonts')[0];
                    btns.style.visibility = '';
                    btns.style.opacity = 1;
                }} onMouseLeave={(e) => {
                    var btns = document.getElementsByName(this.props.id)[0].getElementsByClassName('change-buttonts')[0];
                    btns.style.visibility = 'hidden';
                    btns.style.opacity = 0;
                }}
                id='moved-block' className='higlighted__parentElem' data-content='Logo' style={{
                    paddingTop: this.getPaddingTop(), paddingBottom: this.getPaddingBottom(),
                    border: this.props.selectedElm == this.props.id ? '2px solid #34b9a0' : '',
                    paddingLeft: this.getPaddingLeft(), paddingRight: this.getPaddingRight(),
                    backgroundColor: this.getBackgroundColor(),
                    marginTop: this.getMarginTop(), marginBottom: this.getMarginBottom(), marginLeft: this.getMarginLeft(),
                    marginRight: this.getMarginRight(),
                }} onClick={(e) => e.target.tagName === "TABLE" ? this.props.putElm({ id: this.props.id, type: this.props.type }) : null}>

                {this.renderBlock(this.getType())}
                <div className='change-buttonts' style={{ position: 'absolute', zIndex: 1, top: '8px', right: 32, visibility: 'hidden', opacity: '0', transition: '0.3s all'}}>
                    <img onClick={() => this.styleBack()} Style="cursor: pointer;background: #e6e6e68a;border-radius: 100%;margin-right: 16px;" src="https://adbotest.digitum.nl/static/media/ai_back.svg" />
                    <img onClick={() => this.styleForward()} Style="cursor: pointer;background: #e6e6e68a;border-radius: 100%;" src="https://adbotest.digitum.nl/static/media/ai_forward.svg" />
                </div>
                <Menu
                    parent
                    edit={this.editBlock}
                    canInteract={this.checkIfCanInteract()}
                    delete={this.deleteBlock}
                    copy={this.copyBlock}
                    moveUp={() => this.elemMove('up')}
                    moveDown={() => this.elemMove('down')}
                />
            </Row>
        );
    }

}


function matchDispatchToProps(dispatch) {
    return bindActionCreators({ putElm: putElm, setArr: setArr }, dispatch);
}
const mapStateToProps = state => {
    return {
        arr: state.arr,
        selectedElm: state.selectedElm
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(AIFooter);