import React, { Component } from 'react'
import { Fade } from 'react-reveal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setArr, setCampaignId, setEmailId, setCampaignName, setSelectedChannels } from '../../actions/actions';
import { icons } from '../../assets/icons';

import './styles.css';

class CreateCampaignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            channel: '',
        }
    }
    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (e.target.classList.contains('createCampaign-modal-overlay')) {
            this.props.close();
        }
    }

    setName = (value) => {
        this.setState({ name: value })
        this.props.setCampaignName(value)
    }

    toggleChannel = (channel) => {
        if (this.state.channel === channel) {
            this.setState({ channel: '' });
            this.props.setSelectedChannels('')
        } else {
            this.setState({ channel: channel });
            this.props.setSelectedChannels(channel)
        }
    }

    createCampaign = (e) => {
        e.preventDefault();
        var name = (this.props.campaignName);
        var type = (this.props.selectedChannels);
        if (name.length > 0 && type.length > 0) {
            var json = {
                token: this.props.token,
                name: name,
                types: [parseInt(type)]
            };
            fetch('CreateCampaign', {
                method: "POST",
                body: JSON.stringify(json),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(x => x.json()).then(x => {
                // Put new redux variblse
                this.props.setArr([]);
                this.props.setEmailId(x.emailId);
                this.props.setCampaignId(x.campaignId);
                if (parseInt(type) === 2){
                    this.props.setCurrentPage('ai-constructor');
                }else{
                    this.props.setCurrentPage('constructor');
                }
             
            });
        } else {

        }
    }

    render() {
        return (
            <Fade>
                <div className='createCampaign-modal-overlay'>
                    <div className='createCampaign-modal'>
                        <div className='createCampaign-modal__top'>
                            <h2 className='createCampaign-modal__top-title'>New Campaign</h2>
                            <button onClick={this.props.close} className='createCampaign-modal__top-close'>
                                <img src={icons.close} />
                            </button>
                        </div>
                        <form className='createCampaign-modal__form' onSubmit={this.createCampaign}>
                            <div className='createCampaign-modal__form-group'>
                                <label className='createCampaign-modal__form-label'>Campaign name</label>
                                <input
                                    className='createCampaign-modal__form-input'
                                    type='text'
                                    value={this.state.name}
                                    onChange={(e) => this.setName(e.target.value)}
                                />
                            </div>
                            <div className='createCampaign-modal__form-group'>
                                <label className='createCampaign-modal__form-label'>Channel</label>
                                <div style={{display: 'flex'}}>
                                    <button
                                        value='1'
                                        className='createCampaign-modal__form-channel'
                                        type='button'
                                        onClick={() => this.toggleChannel('1')}
                                        style={{ backgroundColor: this.state.channel === '1' && '#34B9A0' }}
                                    >
                                        Email
                                    </button>
                                    <button
                                        value='2'
                                        className='createCampaign-modal__form-channel'
                                        type='button'
                                        onClick={() => this.toggleChannel('2')}
                                        style={{ backgroundColor: this.state.channel === '2' && '#34B9A0',marginLeft: '12px' }}
                                    >
                                        AI-Email
                                    </button>
                                </div>
                            </div>
                            <div className='createCampaign-modal__form-submit'>
                                <button disabled={!this.state.channel.length || !this.state.name} type='submit'>Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fade>
        )
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setEmailId: setEmailId,
            setArr: setArr,
            setCampaignId: setCampaignId,
            setCampaignName: setCampaignName,
            setSelectedChannels: setSelectedChannels
        }, dispatch
    );
}
const mapStateToProps = state => {
    return {
        campaignName: state.campaignName,
        selectedChannels: state.selectedChannels,
        token: state.token,
        arr: state.arr,
    }
}
export default connect(mapStateToProps, matchDispatchToProps)(CreateCampaignModal);