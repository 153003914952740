import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getAiHeaderBlock = (img, text, text2, params, main, type) => {
    var params_i = img.params;
    var params_t = text.params;
    var params_t2 = text2.params;
    var rtl = false;
    if (params.dir !== undefined && params.dir !== null) {
        rtl = params.dir;
    }

    var cWidth = 600 - parseInt(params_i.width).toString() + 'px';
    /*var width = '601px';
    if (main !== undefined && main !== null) {
        var _params = main;
        var pR = parseInt(_params.paddingRight);
        var pL = parseInt(_params.paddingLeft);

        var _width = 601 - pR - pL;
        width = _width.toString() + 'px';

    }*/
    switch (type) {
        case 'ai-header-1':
            return  <table cellPadding={0} cellSpacing={0} width={600} style={{maxWidth: '600px', width: '600px'}}><tr style={{
                display: 'block', marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight,
                marginBottom: params.marginBottom,  msoPaddingTopAlt: params.paddingTop, msoPaddingLeftAlt: params.paddingLeft, msoPaddingRightAlt: params.paddingRight, msoPaddingBottomAlt: params.paddingBottom
            }}>
                <td align={params_i.align} style={{
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                    paddingBottom: params.paddingBottom,
                    width: params_i.width,
                    backgroundColor: params_i.backgroundColor
                }}>{getImgBlock(params_i)}</td>
                <td align={text.params.align} style={{
                    borderTopRightRadius: params.borderTopRightRadius,
                    width: '100%',
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                    paddingBottom: params.paddingBottom,
                }}>
                    {getTextBlock(params_t, undefined, undefined, undefined, undefined, cWidth)}
                    {getTextBlock(params_t2, undefined, undefined, undefined, undefined, cWidth)}
                </td>

            </tr></table>
            break;
        case 'ai-header-2':
            return  <table cellPadding={0} cellSpacing={0} width={600} style={{maxWidth: '600px', width: '600px'}}><tr style={{
                display: 'block', marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight,
                marginBottom: params.marginBottom, msoPaddingTopAlt: params.paddingTop, msoPaddingLeftAlt: params.paddingLeft, msoPaddingRightAlt: params.paddingRight, msoPaddingBottomAlt: params.paddingBottom
            }}>

                <td align={text.params.align} style={{
                    borderTopRightRadius: params.borderTopRightRadius,
                    width: '100%',
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                    msoPaddingTopAlt: '0px',
                    paddingBottom: params.paddingBottom,
                }}>
                    {getTextBlock(params_t, undefined, undefined, undefined, undefined, cWidth)}
                    {getTextBlock(params_t2, undefined, undefined, undefined, undefined, cWidth)}
                </td>
                <td align={params_i.align} style={{
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                    paddingBottom: params.paddingBottom,
                    width: params_i.width,
                    backgroundColor: params_i.backgroundColor
                }}>{getImgBlock(params_i)}</td>

            </tr></table>
            break;
        case 'ai-header-3':
            return <table cellPadding={0} cellSpacing={0} width={600} style={{maxWidth: '600px', width: '600px'}}><tbody>
                <tr>
                    <td align={params_i.align} style={{
                        borderTopLeftRadius: params.borderTopLeftRadius,
                        borderBottomLeftRadius: params.borderBottomLeftRadius,
                        verticalAlign: 'top',
                        width: params_i.width,
                        backgroundColor: params_i.backgroundColor
                    }}>{getImgBlock(params_i)}</td>
                </tr>
                <tr>
                    <td align={text.params.align} style={{
                        borderTopRightRadius: params.borderTopRightRadius,
                        width: '100%',
                        borderBottomRightRadius: params.borderBottomRightRadius,
                        backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                        paddingBottom: params.paddingBottom,
                    }}>
                        {getTextBlock(params_t,undefined, undefined, undefined, undefined, '600px')}
                        {getTextBlock(params_t2, undefined, undefined, undefined, undefined, '600px')}
                    </td>
                </tr>
            </tbody></table>;
            break;
        case 'ai-header-4':
            return  <table cellPadding={0} cellSpacing={0}><tr style={{
                display: 'block', marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight,
                marginBottom: params.marginBottom
            }}>

                <td align={text.params.align} style={{
                    borderTopRightRadius: params.borderTopRightRadius,
                    width: '100%',
                    borderBottomRightRadius: params.borderBottomRightRadius,
                    backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                    paddingBottom: params.paddingBottom,
                }}>
                    {getTextBlock(params_t, undefined, undefined, undefined, undefined, cWidth)}
                    {getTextBlock(params_t2, undefined, undefined, undefined, undefined, cWidth)}
                </td>
                <td align={params_i.align} style={{
                    borderTopLeftRadius: params.borderTopLeftRadius,
                    borderBottomLeftRadius: params.borderBottomLeftRadius,
                    verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                    paddingBottom: params.paddingBottom,
                    width: params_i.width,
                    backgroundColor: params_i.backgroundColor
                }}>{getImgBlock(params_i)}</td>

            </tr></table>
            break;
        case 'ai-header-5':
            return  <table cellPadding={0} cellSpacing={0}><tbody>
                <tr>
                    <td align={text.params.align} style={{
                        borderTopRightRadius: params.borderTopRightRadius,
                        width: '100%',
                        borderBottomRightRadius: params.borderBottomRightRadius,
                        backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                        paddingBottom: params.paddingBottom,
                    }}>

                        {getTextBlock(params_t, undefined, undefined, undefined, undefined, '600px')}
                    </td>
                </tr>
                <tr>
                    <td align={params_i.align} style={{
                        borderTopLeftRadius: params.borderTopLeftRadius,
                        borderBottomLeftRadius: params.borderBottomLeftRadius,
                        verticalAlign: 'top',
                        width: params_i.width,
                        backgroundColor: params_i.backgroundColor
                    }}>{getImgBlock(params_i)}</td>
                </tr>
                <tr>
                    <td align={text.params.align} style={{
                        borderTopRightRadius: params.borderTopRightRadius,
                        width: '100%',
                        borderBottomRightRadius: params.borderBottomRightRadius,
                        backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                        paddingBottom: params.paddingBottom,
                    }}>

                        {getTextBlock(params_t2, undefined, undefined, undefined, undefined, '600px')}
                    </td>
                </tr>
            </tbody></table>;
        case 'ai-header-6':
            return  <table cellPadding={0} cellSpacing={0}><tbody>
                <tr>
                    <td align={text.params.align} style={{
                        borderTopRightRadius: params.borderTopRightRadius,
                        width: '100%',
                        borderBottomRightRadius: params.borderBottomRightRadius,
                        backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                        paddingBottom: params.paddingBottom,
                    }}>
                        {getTextBlock(params_t, undefined, undefined, undefined, undefined, '600px')}
                        {getTextBlock(params_t2, undefined, undefined, undefined, undefined, '600px')}
                    </td>
                </tr>
                <tr>
                    <td align={params_i.align} style={{
                        borderTopLeftRadius: params.borderTopLeftRadius,
                        borderBottomLeftRadius: params.borderBottomLeftRadius,
                        verticalAlign: 'top',
                        width: params_i.width,
                        backgroundColor: params_i.backgroundColor
                    }}>{getImgBlock(params_i)}</td>
                </tr>
            </tbody></table>;
            break;
            break;
        default:
            break;
    }
}
/*
return <table width="601px" cellspacing="0" align={params.align} cellpadding="0" style={{
    maxWidth: "601px", width: width,
    borderTopLeftRadius: params.borderTopLeftRadius,
    borderBottomLeftRadius: params.borderBottomLeftRadius,
    borderTopRightRadius: params.borderTopRightRadius,
    borderBottomRightRadius: params.borderBottomRightRadius,


    marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
}}>
    <tr >
        {rtl ? <>
            <td align={params_t.align} style={{
                width: '100%',
                borderTopRightRadius: params.borderTopRightRadius,
                borderBottomRightRadius: params.borderBottomRightRadius,
                backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                paddingBottom: params.paddingBottom,
            }}>{getTextBlock(params_t)}</td>
            <td align={params_i.align} style={{
                borderTopLeftRadius: params.borderTopLeftRadius,
                borderBottomLeftRadius: params.borderBottomLeftRadius,
                verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
                paddingBottom: params.paddingBottom,
                width: params_i.width,
                backgroundColor: params_i.backgroundColor
            }}>{getImgBlock(params_i)}</td>
        </> : <> <td align={params_i.align} style={{
            borderTopLeftRadius: params.borderTopLeftRadius,
            borderBottomLeftRadius: params.borderBottomLeftRadius,
            verticalAlign: 'top', paddingTop: params.paddingTop, paddingLeft: params.paddingLeft,
            paddingBottom: params.paddingBottom,
            width: params_i.width,
            backgroundColor: params_i.backgroundColor
        }}>{getImgBlock(params_i)}</td>
            <td align={params_t.align} style={{
                width: '100%',
                borderTopRightRadius: params.borderTopRightRadius,
                borderBottomRightRadius: params.borderBottomRightRadius,
                backgroundColor: params_t.backgroundColor, paddingTop: params.paddingTop, paddingRight: params.paddingRight,
                paddingBottom: params.paddingBottom,
            }}>{getTextBlock(params_t)}</td></>}

    </tr>

</table>;
}*/