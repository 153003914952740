import { Row } from "@react-email/row";
import { getImgBlock } from "./imageBlock";
import { getTextBlock } from "./textBlock";
import { Column } from '@react-email/column';
import { Section } from '@react-email/section';

export const getAiLogoBlock = (img1, img2, img3, params, type) => {
    var params_1 = img1.params;
    var params_2 = img2.params;
    var params_3 = img3.params;
    switch (type) {
        case 'ai-logo-1':
            return <table width={600} style={{ maxWidth: '600px', width: '600px',
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor,
                msoPaddingTopAlt: params.paddingTop, msoPaddingLeftAlt: params.paddingLeft, msoPaddingRightAlt: params.paddingRight, msoPaddingBottomAlt: params.paddingBottom
            }}>
                <Column style={{ width: img1.params.width, msoPaddingTopAlt: img1.params.paddingTop, msoPaddingRightAlt: '0px', msoPaddingBottomAlt: img1.params.paddingBottom}}>{getImgBlock(params_1)}</Column>
                <Column style={{ width: img2.params.width, msoPaddingTopAlt: img2.params.paddingTop, msoPaddingLeftAlt: '0px', msoPaddingRightAlt: '0px', msoPaddingBottomAlt: img2.params.paddingBottom}}>{getImgBlock(params_2)}</Column>
                <Column style={{ msoPaddingLeftAlt: '0px' }}>{getImgBlock(params_3)}</Column>
            </table>;
            break;
        case 'ai-logo-2':
            return <Row style={{
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
            }}>
                <Column style={{ width: img1.params.width }}>{getImgBlock(params_1)}</Column>
            </Row>
            break;
        case 'ai-logo-3':
            return <Row style={{
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor,
                msoPaddingTopAlt: params.paddingTop, msoPaddingLeftAlt: params.paddingLeft, msoPaddingRightAlt: params.paddingRight, msoPaddingBottomAlt: params.paddingBottom
            }}>
                <Column style={{ msoPaddingRightAlt: '0px' }}>{getImgBlock(params_1)}</Column>
                <Column style={{ width: img2.params.width, msoPaddingTopAlt: img2.params.paddingTop, msoPaddingLeftAlt: '0px', msoPaddingRightAlt: '0px', msoPaddingBottomAlt: img2.params.paddingBottom}}>{getImgBlock(params_2)}</Column>
                <Column style={{ width: img1.params.width, msoPaddingTopAlt: img1.params.paddingTop, msoPaddingRightAlt: img1.params.paddingRight, msoPaddingLeftAlt: '0px', msoPaddingBottomAlt: img1.params.paddingBottom}} align='right'>{getImgBlock(params_3)}</Column>
            </Row>;
            break;
        case 'ai-logo-4':
            return <Row style={{
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
            }}>
                <Column>{getImgBlock(params_1)}</Column>
            </Row>;
            break;
        case 'ai-logo-5':
            return <Row style={{
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
            }}>
                <Column style={{ width: img3.params.width }}>{getImgBlock(params_3)}</Column>
            </Row>
            break;
        case 'ai-logo-6':
            return <Row style={{
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
            }}>
                <Column>{getImgBlock(params_3)}</Column>
            </Row>
            break;
        default:
            return <Row style={{
                paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
                marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
            }}></Row>;
            break;
    }
    return <Row style={{
        paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <Column style={{}}>{getImgBlock(params_1)}</Column>
        <Column style={{}}>{getImgBlock(params_2)}</Column>

    </Row>;
}