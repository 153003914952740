import { Row } from "@react-email/row";
import { getListBlock } from "./listBlock";
import { getTextImage } from "./textImage";
import { getTextBlock } from "./textBlock";

function getTwoByTwo(list, type) {
    var arr = [];

    var width = '600px';
    if (type === 'ai-list-2'){
        width = '580px';
    }
    for (var i = 0; i < list.length; i += 2) {
        if (i + 2 <= list.length) {
            arr.push(
                <table cellPadding={0} cellSpacing={0} style={{ width: width, maxWidth: width }} width={width}>
                    <tr style={{ background: list[i].main.params.backgroundColor }}>
                        <td style={{ padding: '0', margin: '0', verticalAlign: 'top' }}>{list[i].data}</td>
                        <td style={{ padding: '0', margin: '0', verticalAlign: 'top' }}>{list[i + 1].data}</td>
                    </tr>
                </table>
            );
        } else {
            arr.push(
                <table cellPadding={0} cellSpacing={0} style={{ width: '600px', maxWidth: '600px' }} width={600}>
                    <tr style={{ background: list[i].main.params.backgroundColor }}>
                        <td style={{ padding: '0', margin: '0', verticalAlign: 'top' }}>{list[i].data}</td>
                        <td></td>
                    </tr>
                </table>
            );
        }

    }
    return arr;
}
function getList(arr, full_arr, type) {
    var list = [];

    for (var i = 0; i < arr.length; i++) {
        if (arr[i].main.type === 'list-block') {
            var text = JSON.parse(JSON.stringify(arr[i].text));
            text.params.fontHeight = (parseInt(text.params.fontHeight) / 2).toString() + 'px';
            text.params.paddingTop = (parseInt(text.params.paddingTop) + (parseInt(text.params.paddingTop) * 0.3)).toString() + 'px';
            list.push({ main: arr[i].main, data: getListBlock(arr[i].img, text, arr[i].main.params, full_arr) })
        }
        else if (arr[i].main.type === 'text-image') {
            var text = JSON.parse(JSON.stringify(arr[i].text));
            //  text.params.fontHeight = (parseInt(text.params.fontHeight) / 2).toString() + 'px';
            list.push({ main: arr[i].main, data: getTextImage(arr[i].img, text, arr[i].main.params, null, full_arr, undefined, type === 'ai-list-2' ? true : undefined) });
        } else {
            list.push(<></>);
        }
    }

    return list;
}
function getContent(params_t, arr, type, full_arr) {
    switch (type) {
        case 'ai-list-1':
            return <>
                {getTextBlock(params_t, undefined, true)}
                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        case 'ai-list-2':
            return <>
                {getTextBlock(params_t, undefined, true, undefined, undefined, '590px')}
                {getTwoByTwo(getList(arr, full_arr, type), type).map(x => x)}
            </>
            break;
        case 'ai-list-3':
            return <>
                {getTextBlock(params_t, undefined, true, undefined, undefined, '590px')}
                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        case 'ai-list-4':
            return <>
                {getTextBlock(params_t, undefined, true, undefined, undefined, '590px')}
                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        case 'ai-list-5':
            return <>
                {getTextBlock(params_t, undefined, true, undefined, undefined, '590px')}
                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        case 'ai-list-6':
            return <>
                {getTextBlock(params_t, undefined, true, undefined, undefined, '590px')}
                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        case 'ai-list-7':
            return <>
                {getTextBlock(params_t, undefined, true, undefined, undefined, '590px')}
                {getList(arr, full_arr, type).map(x => x.data)}
            </>;
            break;
        default:

            break;
    }
    return <></>;
}

export const getAiListBlock = (params, params_t, arr, type, full_arr) => {


    return <table width={600}  style={{width: '600px', maxWidth: '600px',
        paddingTop: params.paddingTop, paddingLeft: params.paddingLeft, paddingRight: params.paddingRight, paddingBottom: params.paddingBottom,
        marginTop: params.marginTop, marginLeft: params.marginLeft, marginRight: params.marginRight, marginBottom: params.marginBottom, backgroundColor: params.backgroundColor
    }}>
        <tr><td>
            {getContent(params_t, arr, type, full_arr)}
        </td></tr>
    </table>;
}